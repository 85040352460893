// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/JosefinSans-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* loading font1 */
@font-face {
  font-family: 'font1';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Define smartphone dimensions */
html, body {
  height: 100%;
  margin: 0; /* Remove default margins */
  padding: 0;
}

.smartphone-container {
  overflow: hidden;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%; /* Changed to width: 100% */
  height: 100vh; /* Keep 100vh to cover the full viewport height */
  margin: 0 auto;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Prevent overflow scrolling */
  box-sizing: border-box;
}

.main-content {
  width: 100%; /* Changed to width: 100% */
  height: 100vh; /* Cover full height of the viewport */
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}

/* Optional: For a better mobile look */
body {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,oBAAoB;EACpB,+DAA+D;EAC/D,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA,iCAAiC;AACjC;EACE,YAAY;EACZ,SAAS,EAAE,2BAA2B;EACtC,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,QAAQ;EACR,SAAS;EACT,WAAW,EAAE,2BAA2B;EACxC,aAAa,EAAE,iDAAiD;EAChE,cAAc;EACd,sBAAsB;EACtB,2CAA2C;EAC3C,gBAAgB,EAAE,+BAA+B;EACjD,sBAAsB;AACxB;;AAEA;EACE,WAAW,EAAE,2BAA2B;EACxC,aAAa,EAAE,sCAAsC;EACrD,UAAU;EACV,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA,uCAAuC;AACvC;EACE,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,SAAS;AACX","sourcesContent":["/* loading font1 */\n@font-face {\n  font-family: 'font1';\n  src: url('./fonts/JosefinSans-SemiBold.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n/* Define smartphone dimensions */\nhtml, body {\n  height: 100%;\n  margin: 0; /* Remove default margins */\n  padding: 0;\n}\n\n.smartphone-container {\n  overflow: hidden;\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  width: 100%; /* Changed to width: 100% */\n  height: 100vh; /* Keep 100vh to cover the full viewport height */\n  margin: 0 auto;\n  border: 1px solid #ccc;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n  overflow: hidden; /* Prevent overflow scrolling */\n  box-sizing: border-box;\n}\n\n.main-content {\n  width: 100%; /* Changed to width: 100% */\n  height: 100vh; /* Cover full height of the viewport */\n  padding: 0;\n  box-sizing: border-box;\n  overflow: hidden;\n}\n\n/* Optional: For a better mobile look */\nbody {\n  background-color: #f5f5f5;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
