import React, { useState, useCallback, useRef } from "react";
import './App.css';
import Map from './components/Map';

function App() {

  const [username, setUsername] = React.useState(null)

  React.useEffect(() => {
    // Get the current path (e.g., /username)
    const path = window.location.pathname;
    // Split the path by "/" and extract the second part
    const extractedUsername = path.split('/')[1];
  
    if (extractedUsername){
      setUsername(extractedUsername)
    }
    //setUsername(extractedUsername); // Set the username in state
  }, []);

  return (
    <div className="smartphone-container">
      <div className="main-content">
        {true && <Map username={username}/>}
      </div>
    </div>
  );
}

export default App;
