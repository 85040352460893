import React from 'react';

const CircularMarker = (props) => {

    const [isImageLoaded, setIsImageLoaded] = React.useState(true)

    const handleError = () => {
        setIsImageLoaded(false); // set state to false if image fails to load
    };

    if (!isImageLoaded){
        return <div></div>
    }

    return (
      <div 
        style={{
          position: 'relative',
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          border: '1px solid white',
          boxShadow: '0 0 5px rgba(0,0,0,0.5)',
          zIndex: 1000
        }}
      >
        <div 
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            overflow: 'hidden'
          }}
        >
            <img src={props.imageUrl} onError={handleError} alt="marker" style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              display: 'block',
            }} />
        </div>
        
        {props.number > 0 && props.isZoomedOut && <div 
          style={{
            position: 'absolute',
            top: '-8px',
            right: '-5px',
            width: '27px',
            height: '27px',
            backgroundColor: 'rgb(162, 192, 244)',
            color: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '14px',
            fontWeight: 'bold',
            zIndex: props.number.toString()
          }}>
            +{props.number}
          </div>}
      </div>
    );
  };

//export default CircularMarker

function areEqual(prevProps, nextProps) {
    return (
        JSON.stringify(prevProps.imageUrl) == JSON.stringify(nextProps.imageUrl) &&
        JSON.stringify(prevProps.number) == JSON.stringify(nextProps.number) &&
        JSON.stringify(prevProps.isZoomedOut) == JSON.stringify(nextProps.isZoomedOut)
    )
  }

export default React.memo(CircularMarker, areEqual)