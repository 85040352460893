import React, { useState, useCallback, useRef } from "react";
import { GoogleMap, useLoadScript, Marker, OverlayView, OverlayViewF } from "@react-google-maps/api";
import { GetUserPosts } from "../network/GetPosts";
import SelectCities from "./SelectCities";
import './Map.css'; 
import MediaModal from "./MediaModal";
import { serverIp } from "../network/ConfigNetwork";
import CircularMarker from "./CircularMarker";

// Add your Google Maps API key here
const API_KEY = 'AIzaSyCtBzS4JJIgt8sHmPrmovOjzHgqMRpqW0g';

//url: "http://127.0.0.1:8080/images/2592_lowqual.webp"

// Map container style (required for GoogleMap)
const mapContainerStyle = {
  width: "100wh",
  height: "100vh",
};


const getInitCoordinates = (posts) => {
  var group1 = 0   // longitude between -140 and -25 (America)
  var group2 = 0   // longitude between -25 and 55 (Europe/Africa)
  var group3 = 0   // longitude between 55 and 140 (Asia)
  var cities = []

  if (posts && posts.length>0){
      posts.forEach(e => {
          if (cities.indexOf(e.city) == -1){
              if (e.longitude < -25){
                  group1 = group1 + 1
              }
              else if (e.longitude > -25 && e.longitude < 55){
                  group2 = group2 + 1
              }
              else if (e.longitude > 55){
                  group3 = group3 + 1
              }
              cities.push(e.city)
          }
      })
  }
  var arr = [group1, group2, group3]
  let group = arr.reduce((maxIndex, currentValue, currentIndex, array) => {
      return currentValue > array[maxIndex] ? currentIndex : maxIndex;
  }, 0);

  var longitudes = [-100, 17, 108]

  return {
    lat: 23.016,
    lng: longitudes[group],
  }
}

function countUniqueElements(arr) {
  const elementCounts = {};

  arr.forEach((element) => {
      elementCounts[element] = (elementCounts[element] || 0) + 1;
  });

  return Object.keys(elementCounts);
}


const getUniqueCities = (allPosts) => {
  var cities = allPosts.map(e => e.city)
  var uniqueCities = countUniqueElements(cities)
  return uniqueCities
}

const getPostsShownZoomOut = (allPosts, uniqueCities) => {
  // gets all the posts to display when zoomed out.
  // if some posts are in no city, we still display them.
  // we add to the post diplay the attribute n_posts_city to display in the bubble.

  if (uniqueCities.length>0){
      var postsShownVar = []
      uniqueCities.forEach(c => {
          if (c != 1000 && c != -1){
              var postsCity = allPosts.filter(e => e.city == parseInt(c))
              if (postsCity.length>0){
                  postsCity.sort((a, b) => b.n_likes + b.n_real_likes - a.n_likes - a.n_real_likes);
              }
              postsCity[0].n_posts_city = postsCity.length
              postsShownVar.push(postsCity[0])
          }
      })
      // add the posts that are in none of the cities
      var postsNoDefinedCity = allPosts.filter(e => e.city == -1)
      postsShownVar = postsShownVar.concat(postsNoDefinedCity)
      return postsShownVar
  }
  else{
      var allPosts2 = [...allPosts]
      var allPosts2Len = allPosts2.sort((a, b) => b.n_likes + b.n_real_likes - a.n_likes - a.n_real_likes);
      return [allPosts2[0]]
  }
}

const addFlags = (postsShownVar, cities) => {
  var postShownVar2 = []
  if (postsShownVar && postsShownVar.length > 0 && cities && cities.length > 0){
      postsShownVar.forEach(p => {
          var city = cities.filter (cc => cc.id == p.city)
          if (city.length>0){
              p.name = city[0].name
              p.flag = city[0].flag
              postShownVar2.push(p)
          }
      })
  }
  return postShownVar2
}

function findExtremePoints(points) {
  return points.reduce((extremes, currentPoint) => {
      // Update most west point
      if (currentPoint.longitude < extremes.west.longitude) {
          extremes.west = currentPoint;
      }
      // Update most east point
      if (currentPoint.longitude > extremes.east.longitude) {
          extremes.east = currentPoint;
      }
      // Update most north point
      if (currentPoint.latitude > extremes.north.latitude) {
          extremes.north = currentPoint;
      }
      // Update most south point
      if (currentPoint.latitude < extremes.south.latitude) {
          extremes.south = currentPoint;
      }
      return extremes;
  }, {
      west: points[0],
      east: points[0],
      north: points[0],
      south: points[0]
  });
}

const  zoomThreshold = 3
const  zoomInValue = 12

const Map = (props) => {

  const [allPosts, setAllPosts] = React.useState(null)
  const [postsShown, setPostsShown] = React.useState([])
  const [region, setRegion] = React.useState(null)
  const [mapReady, setMapReady] = React.useState(false)
  const [postsShownWithFlags, setPostsShownWithFlags] = React.useState(null)
  const [isZoomedOut, setIsZoomedOut] = React.useState(true)
  const [showModal, setShowModal] = React.useState(false)
  const [currentMediaUrl, setCurrentMediaUrl] = React.useState(null)
  const [currentMediaType, setCurrentMediaType] = React.useState(null)
  const [currentCity, setCurrentCity] = React.useState(null)
  const [currentPostId, setCurrentPostId] = React.useState(null)
  const [citiesAll, setCitiesAll] = React.useState(null)
  const [nCities, setNCities] = React.useState(null)
  const [postsShownUniqueCities, setPostsShownUniqueCities] = React.useState(null)
  const [postsModal, setPostsModal] = React.useState(null)
  
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY,
  });

  const mapRef = useRef();

  // On map load, store the map instance in a ref
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  
  React.useEffect(() => {

    if (props.username){
      GetUserPosts(props.username).then(res => {
        //console.log('TEST posts = ' + JSON.stringify(res.data))
        
        if (res.data && res.data.posts && res.data.posts.length>0 && res.data.cities && res.data.cities.length>0){

          setAllPosts(res.data)
          setCitiesAll(res.data.cities)

          var nCitiesVar = [...new Set(res.data.posts.map(e => e.city))].length
          setNCities(nCitiesVar)

          var pinPosition = getInitCoordinates([...res.data.posts])
          setRegion(pinPosition)

          var uniqueCitiesVar = getUniqueCities([...res.data.posts])
          var postsShownVar = getPostsShownZoomOut([...res.data.posts], uniqueCitiesVar)
          setPostsShown(postsShownVar)

          
          var postsShownVarWithFlags = addFlags(postsShownVar, res.data.cities)
          setPostsShownWithFlags(postsShownVarWithFlags) // will not be changed
          
          var uniqueCitiesVar = getUniqueCities([...res.data.posts])
          var postsShownVar = getPostsShownZoomOut([...res.data.posts], uniqueCitiesVar)
          setPostsShownUniqueCities(postsShownVar)
          
          //setPostsShown(res.data)
          setMapReady(true)
        }
      })
      .catch(err => console.log(err))
    }
  }, [props.username])

  const onZoomChanged = React.useCallback(() => {

    if (mapRef.current && allPosts.posts && allPosts.posts.length>0) {
      const currentZoom = mapRef.current.getZoom();
     
      if (currentZoom > zoomThreshold && isZoomedOut) {
          setIsZoomedOut(false)
          setPostsShown([...allPosts.posts])
      } else if (currentZoom <= zoomThreshold && !isZoomedOut){
          setIsZoomedOut(true)
          var uniqueCitiesVar = getUniqueCities([...allPosts.posts])
          var postsShownVar = getPostsShownZoomOut([...allPosts.posts], uniqueCitiesVar)
          setPostsShown(postsShownVar)
      }
      
    }
  }, [allPosts, postsShownUniqueCities, isZoomedOut]);
  

  // Function to move the map to a specific location (smoothly)
  
  const moveToLocation = (newLocation, zoom) => {
    // Move smoothly to new location
    if (mapRef.current) {
      mapRef.current.panTo(newLocation);
      mapRef.current.setZoom(zoom);
    }
    setRegion(newLocation); // Update center state (optional)
  };

  const zoomToCity = (city) => {
    var postsCity = [...allPosts.posts].filter(e => e.city == city)
    setPostsShown(postsCity)
    var extremePoints = findExtremePoints(postsCity)
    var latitudeVar = (extremePoints.north.latitude + extremePoints.south.latitude) / 2
    var longitudeVar = (extremePoints.west.longitude + extremePoints.east.longitude) / 2

    handleFitBounds(
      {lat: latitudeVar, lng: extremePoints.west.longitude}, // left extreme
      {lat: latitudeVar, lng: extremePoints.east.longitude} // right extreme
    )
  }
  


  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  if (!mapReady) return <div>wrong username...</div>;


  const handleFitBounds = (leftExtreme, rightExtreme) => {
    if (mapRef.current) {
      // Define the extreme points
      // const leftExtreme = { lat: 37.7749, lng: -122.4194 }; // San Francisco
      // const rightExtreme = { lat: 40.7128, lng: -74.0060 }; // New York City

      // Create a LatLngBounds object and extend it with the points
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(new window.google.maps.LatLng(leftExtreme.lat, leftExtreme.lng));
      bounds.extend(new window.google.maps.LatLng(rightExtreme.lat, rightExtreme.lng));

      // Fit the map to the bounds
      mapRef.current.fitBounds(bounds);
    }
  };

  return (
    <div className="map">

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={2} // Initial zoom level
        center={region} // Map center based on state
        onLoad={onMapLoad} // Store map instance when loaded
        onZoomChanged={onZoomChanged}
        options={{
          mapTypeId: 'terrain',
          minZoom: 2,
          mapTypeControl: false, // Disable the Map/Satellite control
          streetViewControl: false, // Disable Street View pegman
          zoomControl: false, // Disable zoom control buttons
          fullscreenControl: false, // (Optional) Disable fullscreen control
          gestureHandling: 'greedy',  // to have single touch scrolling
          styles: [
            {
              elementType: 'labels',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'administrative',
              elementType: 'geometry',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi',
              elementType: 'all',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'road',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'water', // This includes oceans, lakes, and rivers
              elementType: 'geometry.fill',
              stylers: [
                { color: '#00bfff' }, // Customize the ocean color here (light blue in this case)
              ],
            },
          ],
        }}
      >
        {postsShown && postsShown.length>0 && postsShown.map(p => {
            return(
              <OverlayViewF
                key={p.id}
                position={{ lat: p.latitude, lng: p.longitude }}  // Position of the marker
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}  // Pane for interaction
              >
                <div
                  onClick={() => {
                    /*
                    if (p.city > -1 ){
                      setShowModal(true)
                      
                      if (p.type == "webp"){
                        setCurrentMediaUrl(`${serverIp}/images/${p.id}.webp`)
                      }
                      else if (p.type == "mp4"){
                        setCurrentMediaUrl(`${serverIp}/images/${p.id}.mp4`)
                      }
                      setCurrentMediaType(p.type)
                      var cityNameVar = citiesAll[citiesAll.map(e => e.id).indexOf(p.city)]
                      setCurrentCity(cityNameVar)
                      setCurrentPostId(p.id)
                    }
                    */
                    
                    if (p.city > -1 && isZoomedOut){
                      zoomToCity(p.city)
                    }
                    else {
                      // open image/video in full screen
                      setShowModal(true)
                      
                      if (p.type == "webp"){
                        setCurrentMediaUrl(`${serverIp}/images/${p.id}.webp`)
                      }
                      else if (p.type == "mp4"){
                        setCurrentMediaUrl(`${serverIp}/images/${p.id}.mp4`)
                      }
                      setCurrentMediaType(p.type)
                      var cityNameVar = citiesAll[citiesAll.map(e => e.id).indexOf(p.city)]
                      setCurrentCity(cityNameVar)
                      setCurrentPostId(p.id)

                      var postsCity = [...allPosts.posts].filter(e => e.city == p.city)
                      setPostsModal(postsCity)
                    }
                    
                  }}
                  style={{ cursor: 'pointer' }}  // Ensure cursor indicates interactivity
                >
                  <CircularMarker 
                    imageUrl={`${serverIp}/images/${p.id}_lowqual.webp`}  // Custom image URL
                    number={p.n_posts_city}  // Number in the bubble
                    isZoomedOut={isZoomedOut}
                  />
                </div>
              </OverlayViewF>
            )
        })}
      </GoogleMap>

      <div className="title">
          <div 
            className="title2"
            onClick={() => {
              var regionVar = getInitCoordinates([...allPosts.posts])
              //animateToRegion(regionVar)
              moveToLocation(regionVar, 2)

              var uniqueCitiesVar = getUniqueCities([...allPosts.posts])
              var postsShownVar = getPostsShownZoomOut([...allPosts.posts], uniqueCitiesVar)
              setPostsShown(postsShownVar)
              
            }}
          >
              {nCities == null && <p className="nameuser_nocity">{props.username}'s map</p>}
              {nCities != null && <p className="nameuser">{props.username}'s map</p>}
              {nCities != null &&<p className="numcities">{nCities} cities</p>}
          </div>
      </div>

      <div className="linkapp">
          <div 
            className="linkapp2"
            onClick={() => { 
                window.location.href = 'https://apps.apple.com/us/app/mnti/id6468454177'
            }}
          >
              <p>create your own map</p>
              {false && <img src={require("../public/icon_apple.png")}  style={{width: '20px', height: '20px', objectFit: 'cover', display: 'block',}}></img>}
          </div>
      </div>

      {postsShownWithFlags && postsShownWithFlags.length > 0 && <SelectCities
        cities={postsShownWithFlags}
        onClick={(city) => {
          zoomToCity(city)
        }}
      />}

      {showModal && (
        <MediaModal 
          postsCity={postsModal}
          mediaUrl={currentMediaUrl} 
          mediaType={currentMediaType}
          city={currentCity}
          onClose={() => {
            setShowModal(false)
          }} 
          next={(type) => {
             // filter all the posts from the same city
             var allPostCity = [...allPosts.posts].filter(e => e.city == currentCity.id)
             var idx = allPostCity.map(e => e.id).indexOf(currentPostId)
             
             if (type == "prev"){
              if (idx > 0){
                  idx = idx - 1
              }
              else {
                idx = allPostCity.length-1
              }
            }

            else if (type == "next"){
              if (idx < allPostCity.length-1){
                  idx = idx + 1
              }
              else {
                idx = 0
              }
            }

             setCurrentPostId(allPostCity[idx].id)
             setCurrentMediaType(allPostCity[idx].type)
             if (allPostCity[idx].type == "webp"){
              setCurrentMediaUrl(`${serverIp}/images/${allPostCity[idx].id}.webp`)
            }
            else if (allPostCity[idx].type == "mp4"){
              setCurrentMediaUrl(`${serverIp}/images/${allPostCity[idx].id}.mp4`)
            }
            setShowModal(true)
          }}
        />
      )}
    </div>
  );
}

export default Map;
