// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.modal-content {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 100px;
}

.modal-content {
  position: relative;
  width: 100vw; /* Full viewport width */
  padding-bottom: calc(100vw * 1.2); /* Aspect ratio 1.2 (Height = 1.2 * Width) */
  overflow: hidden; /* Ensures the image stays within the bounds */
}

.modal-image img{
    position: absolute;
    top: 0;
    left: 0;
    max-width: 90%;
    max-height: 90%;
    object-fit: cover;
    border-radius: 30px;
    border-width: 2px;
    border-color: rgb(162, 192, 244);
    border-style: solid;
    display: inline-block;
    background-color: lightgrey;
  }


.citylabel{
  height: 35px;
  background-color: rgba(162, 192, 244, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  font-size: 16px;
  color: rgba(0,0,0,0.7);
  font-family: 'font1', sans-serif;
}

.flag{
  font-size: 22px;
  margin-right: 7px;
}

.divdots{
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/MediaSwiper.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,YAAY,EAAE,wBAAwB;EACtC,iCAAiC,EAAE,4CAA4C;EAC/E,gBAAgB,EAAE,8CAA8C;AAClE;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;IACjB,gCAAgC;IAChC,mBAAmB;IACnB,qBAAqB;IACrB,2BAA2B;EAC7B;;;AAGF;EACE,YAAY;EACZ,wCAAwC;EACxC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,wCAAwC;EACxC,eAAe;EACf,sBAAsB;EACtB,gCAAgC;AAClC;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":["\n.modal-content {\n  position: absolute;\n  max-width: 100%;\n  overflow: hidden;\n  margin-bottom: 100px;\n}\n\n.modal-content {\n  position: relative;\n  width: 100vw; /* Full viewport width */\n  padding-bottom: calc(100vw * 1.2); /* Aspect ratio 1.2 (Height = 1.2 * Width) */\n  overflow: hidden; /* Ensures the image stays within the bounds */\n}\n\n.modal-image img{\n    position: absolute;\n    top: 0;\n    left: 0;\n    max-width: 90%;\n    max-height: 90%;\n    object-fit: cover;\n    border-radius: 30px;\n    border-width: 2px;\n    border-color: rgb(162, 192, 244);\n    border-style: solid;\n    display: inline-block;\n    background-color: lightgrey;\n  }\n\n\n.citylabel{\n  height: 35px;\n  background-color: rgba(162, 192, 244, 1);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-left: 20px;\n  margin-right: 20px;\n  border-radius: 10px;\n  padding-left: 10px;\n  padding-right: 10px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);\n  font-size: 16px;\n  color: rgba(0,0,0,0.7);\n  font-family: 'font1', sans-serif;\n}\n\n.flag{\n  font-size: 22px;\n  margin-right: 7px;\n}\n\n.divdots{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
