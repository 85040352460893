import React from 'react';
import './SelectCities.css'; 

const items = Array.from({ length: 10 }, (_, i) => `Item ${i + 1}`);

const SelectCities = (props) => {

  if (!props.cities){
    return null
  }

  return (
    <div className="main-selectcities">
        <div className="scroll-container">
        {props.cities.map((item, index) => (
            <div   
                key={item.id} 
                className="scroll-item"
                onClick={() => {
                    props.onClick(item.city)
                }}
            >
                <p className="flag">{item.flag}</p> {item.name}
            </div>
        ))}
        </div>
    </div>
  );
};

export default SelectCities;