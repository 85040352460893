import React from 'react';
import './MediaModal.css'; 
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import MediaSwiper from './MediaSwiper';

const MediaModal = (props) => {

  const [isImageLoaded, setIsImageLoaded] = React.useState(true)

  const handleError = () => {
    setIsImageLoaded(false); // set state to false if image fails to load
  };

  return (
    <div className="modal-overlay" onClick={props.onClose}>
      {false && <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {isImageLoaded && props.mediaType == "webp" && <img src={props.mediaUrl} alt="Full-screen" className="modal-image" onError={handleError}/>}
        
        {isImageLoaded && props.mediaType == "mp4" && <video controls autoPlay muted onError={handleError}>
            <source src={props.mediaUrl} type="video/mp4" />
        </video>}
      </div>}

      <div className="modal-content2" onClick={(e) => e.stopPropagation()}>
        <div className="modal-image">
          <MediaSwiper
            postsCity={props.postsCity}
            city={props.city}
          />
        </div>
      </div>

      <div className="legend">

        {false && <div 
          style={{ display: 'flex', marginRight: '20px', width: '40px', height: '40px', alignItems: 'center', fontSize: '16px', borderRadius: '40px', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(162, 192, 244, 1)'}}
          onClick={(e) => {
              e.stopPropagation()
              props.next("prev")
          }}
        >
          <FaArrowLeft style={{color: 'white'}} /> 
        </div>}

        {false && <div   
            className="city"
        >
            <p className="flag">{props.city.flag}</p> {props.city.name}
        </div>}

        {false && <div 
          style={{ display: 'flex', marginLeft: '20px', width: '40px', height: '40px', alignItems: 'center', fontSize: '16px', borderRadius: '40px', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(162, 192, 244, 1)'}}
          onClick={(e) => {
            e.stopPropagation()
            props.next("next")
          }}
        >
          <FaArrowRight style={{color: 'white'}} /> 
        </div>}
      </div>
    </div>
  );
};

export default MediaModal;
