import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'; // Import Swiper and SwiperSlide components
import 'swiper/css'; // Import Swiper core styles
import { serverIp } from '../network/ConfigNetwork';
import './MediaSwiper.css'; 


const MediaSwiper = (props) => {

    const [currentIndex, setCurrentIndex] = React.useState(0);

  return (
    <div style={{}}>

        <div className='divdots'>
            {props.postsCity && props.postsCity.length > 0 && props.postsCity.slice(0, 7).map((post, index) => {
                return(
                    <div style={{width: currentIndex == index ? '8px' : '5px', height: currentIndex == index ? '8px' : '5px', marginLeft: '3px', marginRight: '3px', borderRadius: '10px', backgroundColor: 'lightgrey'}}></div>
                )
            })}
        </div>

        <Swiper
            spaceBetween={0} // Space between slides
            slidesPerView={1} // Only show one slide at a time (full screen)
            pagination={{ clickable: true }} // Enable pagination (dots navigation)
            onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
        >
        {props.postsCity && props.postsCity.length > 0 && props.postsCity.slice(0, 7).map((post, index) => {
            return(
                <SwiperSlide key={post.id}>
                    <div
                        style={{
                            width: '100vw',
                            height: '120vw',
                        }}
                    >
                        {post.type == "webp" && <img src={`${serverIp}/images/${post.id}.webp`} alt="Full-screen"  style={{width: '90%', height: '90%', marginTop: '5%', marginLeft: '5%'}}/>}
                        {post.type == "mp4" && <video controls autoPlay muted style={{width: '90%', height: '90%', marginTop: '5%', marginLeft: '5%'}}>
                            <source src={`${serverIp}/images/${post.id}.mp4`} alt="Full-screen" type="video/mp4" />
                        </video>}
                    </div>
                </SwiperSlide>
            )
            })}
        </Swiper>

        <div className='citylabel'>
            <p>{props.city.flag}</p> {props.city.name}
        </div>
    </div>
  );
};

export default MediaSwiper;
